<template>
  <div class="powerPerson">

    <div class="head_banner">
          <div class="menu">
      <div
        class="menu_logo"
        style="cursor: pointer"
        @click="goEle('https://www.woxuwireless.com/previewSolution/157')"
      ></div>
      <div class="menu_tell">        
                <div class="case-image">
                  <img src="@/assets/powerperson/tell.png" style="width: 90%" />
                </div>
                17751793945
              </div>
    </div>
      <div
        class="head_txt"
      >
        <div class="arrow"></div>
        <div class="bannertitle">
        <h1>智慧电厂解决方案</h1>
        <h2>UWB高精度位置数据为电力人员安全"保驾护航"</h2>
        </div>

        <!-- <h1>电力人员安全管理<br />解决方案</h1> -->
      </div>
    </div>

    <div class="content">
      <div class="guide">
        <div class="container">
          <div class="guide-content">
            <div class="topBord"></div>
            <div class="bottomBord"></div>
            <h1 class="guide-header">导言</h1>
            <div class="guide-box">
              <p>
                电力行业作为国民经济的基础产业，其数字化建设一直备受关注。在最新发布的《中华人民共和
                国国民经济和社会发展第十四个五年规划和2035年远景目标纲要》里也提到关于加快推动产业数字化建设。智慧电厂数字化的重要性不言而喻。
              </p>
              <p>
                安全管理作为电厂数字化建设中最重要的一环，其中一个关键方面是人员的安全管理。人员作业的不规范、到岗到位无法及时确认、外委人员监管难、行为违章预防及追溯难等都是当前生产安全管理面临的基本问题。基于电力数字化、智慧化的发展趋势，最佳的方案是将大数据分析、物联网、云平台等新一代技术融合作为一种有效的技防手段介入到安全生产管理中，从而构建数字化、信息化、智能化的管理平台来切实解决当前的痛点。
              </p>
            </div>
          </div>
        </div>
      </div>




      <div class="solution">
        <div class="container">
          <h1
            class=" analysis-head  uwbhead"
          >
            UWB电厂人员安全管理解决方案
            <br/>
            <hr/>
          </h1>
          <div class="solution-content">
            <div class="solution-box-left">
              <div class="solution-txt" style="width: 65%; flex: 1">
                <p>
                  沃旭通讯凭借在电力能源领域多年的实践经验和技术沉淀，基于UWB定位技术的人员安全管理解决方案，为电力能源企业提供厘米级别的高精准位置服务。
                </p>
                <p>
                  在规定区域内部署UWB定位基站，作业人员、巡检人员及外协人员等佩戴定位标签，采用0维存在性检测、1维线性轨迹定位和2维区域平面定位的多维定位模式,实现人员精
                  准定位，轨迹追踪，人员数量统计，工作时长统计，围栏告警，智能巡检等业务功能，并与三维、视频监控、人脸识别、两票管理等多种技术融入到智慧电厂安全管理各
                  项环节中，实现了将现实场景信息通过数字孪生的手段呈现于管理者面前，从而达到高效监管，统览全局的目标。
                </p>
                  <p>
                    <strong>定位对象：</strong>
                    作业人员、巡检人员、外协人员、外来访客
                  </p>
                  <p>
                    <strong>应用场景：</strong>
                    火电厂、水电站、储能电站、风电、变电站
                  </p>
              </div>
            </div>
            <div class="solution-image">
              <img src="@/assets/powerperson/solutionbg.jpg" width="100%" />
            </div>
          </div>
        </div>
      </div>



            <div class="frame">
        <div class="container">
          <h1
            class=" analysis-head"
    
          >
            系统架构
             <br/>
            <hr/>
          </h1>
          <div class="frame-content">
            <div class="frame-left">
              <p>
                <strong>感知层：</strong>
                感知层由定位标签（人、车、物标签）和定位基站组成，通过定位基站与定位标签的UWB定位信道实现对定位标签的定位。并可联动各类传感器，实现对现场其他数据的采集。
              </p>
              <p>
                <strong>传输层：</strong>
                传输层分别可以通过5G、无线AP、5.8G
                Wi-Fi、有线以太网（如POE）的网络传输形式为定位基站提供数据传输链路。
              </p>
              <p>
                <strong>解算层：</strong>
                解算层为UWB定位引擎软件，对底层信息数据采集后通过定位引擎的算法处理，得到定位标签的精准坐标。
              </p>
              <p>
                <strong> 应用层：</strong>
                应用层软件实现定位显示，轨迹回放等基础功能，以及基于位置数据拓展的电子围栏、智能巡检、考勤统计、视频联动等业务功能。
              </p>
            </div>
            <div class="fram-right" style="text-align:right">
              <img src="@/assets/powerperson/frame.png" width="90%" />
            </div>
          </div>
        </div>
      </div>

      <div class="advantage">
        <div class="container">
          <h1 class=" analysis-head">方案优势
             <br/>
            <hr/>
          </h1>
          <div class="advantage-content">
            <div class="advantage-box" v-for="item in adList" :key="item.id">
              <div
                class="advantage-icon"
                :style="`background-image:url(${item.iconSrc})`"
              ></div>
              <div class="advantage-title">{{ item.title }}</div>
              <div class="advantage-txt" v-html="item.txt"></div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>





      <div class="application">
        <div class="container">
          <h1 class=" analysis-head">应用功能
             <br/>
            <hr/>
          </h1>
          <div class="application-box">
            <div class="application-tab">
              <div class="application-tab-header">
                <span
                  class="tab-title"
                  v-for="item in applicationList"
                  :key="item.id"
                  :class="{ active: applicationIndex == item.id }"
                  @click="choseTab(item)"
                >
                  <span class="tab-txt">{{ item.title }}</span>
                  <span class="downArrow" v-if="applicationIndex == item.id">
                    <img src="@/assets/powerperson/downArrow.png" />
                  </span>
                </span>
              </div>
              <div class="application-tab-txt">
                <div class="tabimg">
                  <img width="100%" :src="curtab.imgUrl" />
                </div>
                <div class="tabTxt">
                  <ul>
                    <li v-for="(item, index) in curtab.txt" :key="index">
                      <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="bg"></div>

      <div class="caseSwiper">
        <div class="container">
         <h1
            class=" analysis-head"
          >
            成功案例
                 <br/>
            <hr/>
          </h1>
          <el-carousel indicator-position="outside" height="500px">
            <el-carousel-item>
              <div class="carouselItem">
                <div class="carouseltxt">
                  <h3>
                    <span class="caseIcon"
                  ><img src="@/assets/powerperson/case1.png" /></span>国家能源泰州电厂
                  </h3>
                  <p>
                         <strong> 业务需求:</strong>
                   <br>
          
                    在日常的安全管理中，由于电厂环境复杂，设备和资源种类众多，巡检路线错综复杂，稍有不慎，就容易导致重大的人身伤亡等安全责任事故。如何采取有效的措施保障电厂长期稳定、可靠的安全运行，是国家能源泰州电厂最关心的问题之一。因此，国家能源泰州电厂急需一套更高效、更安全的人员定位管理手段，用“技防”替代“人防”，实现厂区人员安全的管理以及生产管理效率的提升。</p>
                  <p>
                   
                         <strong>  解决方案:</strong>
                   <br>
                    <ul class="caseUl">
                      <li>
                                              <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />基于UWB定位技术，建立一套3D人员定位管理平台。</li>
                      <li>
                                              <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />在电厂区域内部署UWB定位基站，作业、巡检及外协人员均佩戴UWB工牌标签。
                      </li>
                      <li>
                                              <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />基于人员精确位置管理，实现对电厂区域作业管理、高风险作业监控、到岗到位管理、巡检过程管理等，保证现场人员的行为可控、位置可视。
                      </li>
                      <li>
                                              <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />将UWB定位技术与5G网络的结合，助力国家能泰州电厂建设首个5G+UWB智慧电厂，打造行业标杆。
                      </li>
                    </ul>
                  </p>
                
                </div>
                  <div class="carouselImg">
                   <img src="@/assets/powerperson/case.png" style="width: 85%" />
                </div>
              </div>
            </el-carousel-item>


            <el-carousel-item>
            <div class="carouselItem">
                <div class="carouseltxt">
                  <h3><span class="caseIcon"
                  ><img src="@/assets/powerperson/case1.png" /></span
                >某500kV变电站项目</h3>
                  <p>
                    <strong> 业务需求:</strong>
                   <br>
                    在变电站作业区域，经常存在检修人员走错区域，登高作业监管不到位，接地线误操作等一系列现象，现希望通过UWB定位技术来解决现场作业的安全问题。
         </p>
                  <p>             <strong> 解决方案:</strong>
                   <br>
                    <ul class="caseUl">
                      <li>
                      <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />
                        500kV规模的变电站部署214台UWB定位基站，覆盖区域包含主控通信楼、主变室、继保室、室外高层等区域，目标人数100左右；</li>
                      <li>
                        <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />
                       利用UWB定位技术，实现对大型机械触臂、人员高空作业的三维定位；
                      </li>
                      <li>
                        <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />
                        基于基础位置数据实现轨迹追踪、数量统计、电子围栏、异常预警、登高作业跌落检测、接地线挂接判断等业务功能。
                      </li>
                
                    </ul>  
                    </p> 
                     <p>
     
                    <strong> 客户价值:</strong>
                   <br/>
                    <ul class="caseUl">
                      <li>
                                              <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />
                        利用智能化管理技术，提高应急和事件的响应速度，提升变电站安全管理效率；</li>
                      <li>
                                              <img
                        src="@/assets/powerperson/check.png"
                        style="margin-right: 5px; width: 14px"
                      />
                       实现智能变电站运营管理的数字化、智能化和可视化管理。
                      </li>
                    </ul>

                  </p>
                </div>
                  <div class="carouselImg">
                   <img src="@/assets/powerperson/caseST.png" style="width: 85%" />
                </div>
              </div>

            </el-carousel-item>

          </el-carousel>
        </div>
      </div>

      <!-- <div class="case">
        <div class="container">
          <h1
            class=" analysis-head"
            style="cursor: pointer"
            @click="goEle('https://www.woxuwireless.com/projectExamples')"
          >
            成功案例
          </h1>
          <div class="case-content">
            <div class="case-box">
              <h3>
                <span class="caseIcon"
                  ><img src="@/assets/powerperson/case1.png" /></span
                >项目名称：国家能源泰州电厂
              </h3>
              业务需求：在日常的安全管理中，由于电厂环境复杂，设备和资源种类众多，巡检路线错综复杂，稍有不慎，就容易导致重大的人身伤亡等安全责任事故。如何采取有效的措施保障电厂长期稳定、可靠的安全运行，是国家能源泰州电厂最关心的问题之一。因此，国家能源泰州电厂急需一套更高效、更安全的人员定位管理手段，用“技防”替代“人防”，实现厂区人员安全的管理以及生产管理效率的提升。
            </div>
            <div class="case-box">
              <h3>
                <span class="caseIcon"
                  ><img src="@/assets/powerperson/case2.png" /></span
                >解决方案：
              </h3>
              <ul class="caseUl">
                <li>基于UWB定位技术，建立一套3D人员定位管理平台。</li>
                <li>
                  在电厂区域内部署UWB定位基站，作业、巡检及外协人员均佩戴UWB工牌标签。
                </li>
                <li>
                  基于人员精确位置管理，实现对电厂区域作业管理、高风险作业监控、到岗到位管理、巡检过程管理等，保证现场人员的行为可控、位置可视。
                </li>
                <li>
                  将UWB定位技术与5G网络的结合，助力国家能泰州电厂建设首个5G+UWB智慧电厂，打造行业标杆。
                </li>
              </ul>
            </div>

            <div class="case-image">
              <img src="@/assets/powerperson/case.png" style="width: 85%" />
            </div>
          </div>
        </div>
      </div> -->

      <div class="footer">
        <div class="container">
          <h2 class="fparter">
            <span class="fparter-line"></span>
            <span
              class="fparter-title"
    
              >合作伙伴</span
            >
          </h2>
          <div
            class="fparter-img"

          >
            <img src="@/assets/powerperson/partner.png" style="width: 100%" />
          </div>
          <div class="fparter-bot">
            <div class="fparter-code">
              <div class="casevideo">
                <img
                  src="@/assets/powerperson/code1.png"
                  alt=""
                  style="width: 100%"
                />
                <span>扫码查看案例视频</span>
              </div>
              <div class="casevideo">
                <img
                  src="@/assets/powerperson/code.png"
                  alt=""
                  style="width: 100%"
                />
                <span>在线咨询</span>
              </div>
            </div>
            <div class="fparter-info">
              <div class="phone">
                <div class="case-image">
                  <img src="@/assets/powerperson/tell.png" style="width: 90%" />
                </div>
                17751793945
              </div>
              <div
                class="firm"
               
              >
                <label>公司：</label>南京沃旭通讯科技有限公司
              </div>
              <div
                class="address"
      
              >
                <label>地址：</label
                >江苏省南京市玄武区玄武大道699-8号徐庄软件产业基地9栋4楼
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "@/assets/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  head() {
    return {
      title: "关于沃旭",
      meta: [
        { name: "keywords", content: "关于沃旭，沃旭通讯" },
        {
          hid: "description",
          name: "description",
          content:
            "我们拥有前沿的技术，通过不断创新和优化致力于为用户提供优质的行业解决方案，努力成为全球位置服务IoT领导者",
        },
      ],
    };
  },

  data() {
    return {
      swiperOption: {
        initialSlide: 1,
        slidesPerView: 3,
        centeredSlides: true,
        // spaceBetween: -50,
        spaceBetween: -45,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        // slidesPerGroup: 1,
        loop: false,
        // roundLengths: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        preventLinksPropagation: false, // 阻止点击事件冒泡
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // on: {
        //   //当slide位置变化时触发
        //   slideChange: function () {
        //     self.getTouchPage(this.activeIndex + 1);
        //   },
        // },
      },

      applicationIndex: 1,
      curtab: {},
      allMenu: [],
      menuLabelList: [],
      start: 1,
      year: 10,
      authory: 60,
      cases: 1000,
      analysisList: [
        {
          id: "01",
          isActive: true,
          title: "01",
          iconSrc: require("@/assets/powerperson/analysis1.png"),
          txt: "在岗人员日常操作无法做到不间断的监管，管理层无法实时掌握现场人员作业情况，无法快速判断和响应",
        },
        {
          id: "02",
          isActive: false,
          title: "沃旭使命",
          iconSrc: require("@/assets/powerperson/analysis2.png"),
          txt: "外协人员进厂作业，安全管控缺少有效的监督手段，存在较大安全隐患和统计等问题。",
        },
        {
          id: "03",
          isActive: false,
          title: "公司愿景",
          iconSrc: require("@/assets/powerperson/analysis3.png"),
          txt: "3.电厂各类监控系统相对独立，不能为各级管理人员提供应急管理平台与全面决策支持，管理效率低下。",
        },
      ],
      adList: [
        {
          id: "1",
          isActive: false,
          title: "更高的定位精度",
          iconSrc: require("@/assets/powerperson/ad1.png"),
          txt: "<div class='ad_txt'>LOS平均精度优于30cm</div><div class='ad_txt'>NLOS精度优于50cm</div>",
        },
        {
          id: "2",
          isActive: false,
          title: "更远的覆盖距离",
          iconSrc: require("@/assets/powerperson/ad2.png"),
          txt: "<div class='ad_txt'>普通模式50米</div><div class='ad_txt'>增强模式大于300米</div>",
        },
        {
          id: "3",
          isActive: false,
          title: "系统容量大(单区域)",
          iconSrc: require("@/assets/powerperson/ad3.png"),
          txt: "<div class='ad_txt'>TOF定位240HZ </div><div class='ad_txt'>TDOA定位950HZ</div>",
        },
        {
          id: "4",
          isActive: false,
          title: "多技术融合",
          iconSrc: require("@/assets/powerperson/ad4.png"),
          txt: "<div class='ad_txt'>有线/5G组网模式</div><div class='ad_txt'>WLAN+UWB双网合一</div>",
        },
        {
          id: "5",
          isActive: false,
          title: "超低功耗",
          iconSrc: require("@/assets/powerperson/ad5.png"),
          txt: "<div class='ad_txt'>ToF 大于1个月</div><div class='ad_txt'>TDoA 大于3个月</div>",
        },
        {
          id: "6",
          isActive: false,
          title: "易二次开发",
          iconSrc: require("@/assets/powerperson/ad6.png"),
          txt: "<div class='ad_txt'>提供API接口</div><div class='ad_txt'>方便二次开发</div>",
        },
      ],
      applicationList: [
        {
          id: 1,
          title: "人员在岗管理，实现人员可视化、智能化管理",
          imgUrl: require("@/assets/powerperson/a1.png"),
          txt: [
            {
              name: "人员实时位置显示",
            },
            {
              name: "访客及外协人员管理",
            },
            {
              name: "登高作业人员定位",
            },
            {
              name: "巡检轨迹的追踪及回放",
            },
            {
              name: "在岗工时统计管理",
            },
          ],
        },
        {
          id: 2,
          title: "在岗安全管控，提升电力安全管理效率",
          imgUrl: require("@/assets/powerperson/a2.png"),
          txt: [
            {
              name: "电子围栏告警",
            },
            {
              name: "区域权限管理",
            },
            {
              name: "重点区域预警管控",
            },
            {
              name: "SOS紧急告警",
            },
            {
              name: "跌落告警提示",
            },
          ],
        },
        {
          id: 3,
          title: "多系统融合，实现数据共享、信息互通",
          imgUrl: require("@/assets/powerperson/a3.png"),
          txt: [
            {
              name: "与三维的结合",
            },
            {
              name: "联动门禁系统",
            },
            {
              name: "摄像头联动",
            },
            {
              name: "两票融合管理",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    this.curtab = this.applicationList[0];
  },
  methods: {
    goEle(url) {
      // this.$router.push({path:"https://www.woxuwireless.com/previewSolution/157"});
      window.location.href = url;
    },
    choseTab(item) {
      this.applicationIndex = item.id;
      this.curtab = item;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
 
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
  box-sizing: border-box;
      display: flex;
    align-items: center;
}
.menu_logo {
  background: url("~@/assets/powerperson/logo.png") center center;
  background-size: cover;
  width: 350px;
  height: 55px;
}
.menu_tell{
      display: flex;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    .case-image {
      width: 24px;
      margin-right: 15px;
      img {
        width: 100%;
      }
    }
}
.menu-content {
  display: flex;
  justify-content: space-between;
}
.head_banner {
  position: relative;
  height: 40vw;
  overflow: hidden;
  min-height: 329px;

  // background: url("~@/assets/powerperson/headbg.png") no-repeat;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
    url("~@/assets/powerperson/banner2.jpg") top center;
  // background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
  //   url("https://uwb.woxuwireless.com/assets/imgwx/product/industry1.jpg") top
  //     center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  .head_txt {
    display: flex;
    background: url("~@/assets/powerperson/headbg1.png") no-repeat;
    padding: 35px 20px;
    // background-size: 100% 100%;
 
        background-size: 154% 100%;
  width: 70%;
    .arrow {
      background: url("~@/assets/powerperson/headarrow.png") no-repeat;
    width: 75px;
    height: 93px;
    background-size: 100% 100%;
    margin: 10px 25px;
    }
    h1 {
      font-size: 40px;
      color: white;
    }
    h2{
        font-size: 28px;
        color: white;
    }
  }
}

.content {
  .guide {
    position: relative;
    height: 320px;
    .guide-content {
      // width: 50%;
      margin: 0 auto;
      background: rgb(237 237 237 / 78%);
      display: flex;
      padding: 130px 40px 40px 40px;
      position: relative;
      z-index: 2;
      top: -80px;
      min-width: 665px;
      box-shadow: 0px 7px 13px -7px grey;
    }

    .topBord {
      position: absolute;
      width: 24px;
      height: 25px;
      top: 110px;
      left: 20px;
      box-shadow: -3px -3px 2px -1px #b2b1b1;
    }
    .bottomBord {
      position: absolute;
      width: 24px;
      height: 25px;
      right: 20px;
      bottom: 20px;
      box-shadow: 3px 3px 2px -1px #b2b1b1;
    }
    .guide-header {
      font-size: 23px;
      width: 190px;
      color: #2c2c2c;
    }
    .guide-box {
      font-size: 16px;
      letter-spacing: 1px;
      margin-left: 12px;
    }
  }

  .analysis {
    background: url("~@/assets/powerperson/analysisbg.png") no-repeat;
    background-size: cover;
    padding: 40px 0 30px;
    .analysis-content {
      display: flex;
      justify-content: center;
      // margin: 85px auto 30px;
      position: relative;
    }
    .analysis-box {
      box-shadow: 0px 1px 5px 0px grey;
      text-align: center;
      padding: 30px;
      background: #fff;
      position: relative;
      // margin: 0 15px;
      display: flex;
      flex-direction: column;
      min-height: 226px;
      .analysis-icon {
        width: 80px;
        height: 80px;
        background: url("~@/assets/powerperson/analysis1.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -46px;
        left: 38%;
      }
      .analysis-num {
        margin: 20px;
        font-size: 34px;
        color: #4d4d4d;
      }
      .analysis-txt {
        text-align: left;
      }
      .line {
        border-bottom: 2px solid #9b9b9b;
        width: 30%;
        margin: 34px auto 0;
      }
    }
  }

  .solution {
    padding-top: 40px;
    .solution-box-left,
    .solution-box-right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 30px;
    }
    .solution-txt {
      padding: 0 45px;
    }
    .solution-image {
      margin: 0 auto;
    }

  }

  .advantage {
    padding: 40px 0px;
    background: url("~@/assets/powerperson/adbg.png") no-repeat;
    background-size: cover;
    .advantage-title {
      color: #2169e0;
      font-weight: 600;
    }
    .advantage-box {
      background: #fff;
      margin: 10px;
      width: 30%;

      text-align: center;
      box-shadow: 0px 0px 4px 0px #b6b0b0;
      position: relative;
    }
    .advantage-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .advantage-icon {
      width: 60px;
      height: 60px;
      background-size: 100% 100%;
      margin: 20px auto;
    }
    .advantage-txt {
      margin: 10px 10px 25px;
      color: #3f3f3f;
      font-size: 15px;
      line-height: 26px;
      text-shadow: 0 0 #afafaf;
    }
  }

  .application {
    // padding: 40px 0px;

    .container{
      padding: 40px 0px;
    }
    .application-tab {
      // width: 65%;
      margin: 0 auto;
      .application-tab-header {
        display: flex;
        width: 100%;
        .tab-title {
          position: relative;
          padding-top: 8px;
          .tab-txt {
            padding: 10px 60px 0px;
            display: block;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #888888;
          }
        }
        .tab-title.active::before {
          content: "";
          border: #2169e0 3px solid;
          position: absolute;
          width: 99%;
          top: 0;
        }
      }
      .application-tab-header > span {
        border: 1px solid #ededed;
        flex: 1;
      }
      .downArrow {
        width: 20px;
        display: block;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }

      .application-tab-txt {
        display: flex;
        border: 1px solid #ededed;
        padding: 16px;
        box-shadow: 0px 5px 8px 0px #eeeeee;
        justify-content: space-around;
        align-items: center;
        .tabTxt {
          color:#323232;
          font-size: 19px;
        }
        .tabTxt > ul > li {
          margin-top: 8px;
          list-style-type: none;
        }
      }
    }
  }

  .bg {
    height: 300px;
    background: url("~@/assets/powerperson/bg.png") no-repeat;
    background-size: cover;
  }
  .case {
    padding: 40px 0px 80px;
    .case-image {
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
    .case-box {
      margin: 25px auto;
      h3 {
        color: #4d4d4d;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

    }
    .caseIcon {
      width: 36px;
      display: inline-block;
      margin: 20px 20px 20px 0;
      img {
        width: 100%;
      }
    }
  }

  .footer {
    background: #414c64;
    padding: 40px 0px;
    .fparter {
      position: relative;
      width: 360px;
      margin: 30px auto;
      text-align: center;
      .fparter-line {
        position: absolute;
        border: 1px solid #fff;
        /* width: 250px; */
        width: 100%;
        left: 0;
        top: 17px;
      }
      .fparter-title {
        color: #fff;
        background: #414c64;
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 63%;
      }
    }

    .fparter-bot {
      display: flex;
      margin: 50px auto;
      .fparter-code {
        display: flex;
        margin: 0px 32px;
        width: 50%;
        justify-content: space-around;
        .casevideo {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 15px;
          color: #fff;
          width: 150px;
          text-align: center;
        }
        .casevideo > span {
          margin-top: 6px;
        }
      }
    }

    .fparter-info {
      color: #fff;
      width: 41%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 60px;
      border-left: 1px solid;
    }
    .phone {
      display: flex;
      margin-bottom: -4px;
    }
    .case-image {
      width: 24px;
      margin-right: 15px;
      img {
        width: 100%;
      }
    }
    .fparter-info > div {
      margin: 4px;
    }
  }

  .frame {
    // background: #f1f2f4;
    padding: 40px 0;
    .frame-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .frame-left{
            width: 80%;
      }
      .frame-right {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
  .caseSwiper{
    padding: 40px 0;
    .carouselItem{
      display: flex;
    justify-content: space-between;
    align-items: center;
    .carouseltxt{
      width: 100%;
    }
    .carouselImg{
      text-align: right;
    }
    
    }
  }
}

// . {
//   color: #2169e0;
//   border: 2px solid #2169e0;
//   width: 300px;
//   text-align: center;
//   font-size: 24px;
//   padding: 5px 0;
// margin: 30px auto 45px;
// }
.solutionimage {
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
}
.left-image {
  box-shadow: -3px 4px 5px 0px #878787;
  // background-image: url("~@/assets/powerperson/solutionleft.png");
}
.right-image {
  height: 204px;
  width: 590px;
  box-shadow: 4px 6px 5px 0px #878787;
  background-image: url("~@/assets/powerperson/solutionright.png");
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1120px;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  padding: 46px 0;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 355px !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.78);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
p{
  margin: 15px 0;
}


h3{
  display: flex;
  align-items: center;
    .caseIcon {
      width: 22px;
      display: inline-block;
      margin: -1px 13px 0px 0;
      img {
        width: 100%;
      }
    }
}
hr{
    border: 2px solid #2169E0;
    width: 50px;
    display: inline-block;
    margin: 5px auto;
    background: #2169E0;
}

.uwbhead,.analysis-head {
    font-size: 24px;
    text-align: center;
    margin: 0 auto 20px;
}

</style>

